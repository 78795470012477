<template>
  <div class="p-3 pt-0">
    <div class="limit p-3">
      <h1 class="title-header">Inicio</h1>
      <div class="content">
        <div class="content-left">
          <Commitment />
          <Financial />
          <PatientTalk />
        </div>
        <div class="content-right">
          <AlertNotification />
          <SecondOpinion />
          <ResultExamsImagem />
          <ResultExamsLaboratory />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { ref } from "vue";
import Commitment from "./Commitment.vue";
import Financial from "./Financial.vue";
import PatientTalk from "./PatientTalk.vue";
import AlertNotification from "./AlertNotifications.vue";
import SecondOpinion from "./SecondOpinion.vue";
import ResultExamsImagem from "./ResultExamsImagem.vue";
import ResultExamsLaboratory from "./ResultExamsLaboratory.vue";

export default {
  components: {
    Commitment,
    Financial,
    PatientTalk,
    AlertNotification,
    SecondOpinion,
    ResultExamsImagem,
    ResultExamsLaboratory,
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.limit {
  max-width: 100%;
}
.btn {
  width: max-content;
  height: 40px;
}
.content {
  display: flex;
  width: 100%;
  gap: 16px;
  margin-top: 36px;
  .content-left {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 469px;
    min-height: 1614px;
  }
  .content-right {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 609px;
    min-height: 1614px;
  }
  h1 {
    color: #4f4f4f;
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
  }
  .p-14 {
    padding: 14px;
  }
  @media (max-width: 1211px) {
    flex-direction: column;
    .content-left{
      width: 100%;
    }
    .content-right{
      width: 100%;
    }
  }
}
</style>