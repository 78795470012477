<template>
  <div class="card-frequency">
    <div class="flex justify-content-between align-items-center">
      <h1>Financeiro</h1>
      <div class="flex align-items-center" style="gap: 17px; cursor: pointer">
        <div class="btn-filters">
          <Button
            class="btn"
            v-for:="item in btnFilter"
            :class="isFilter(item)"
            @click="changeFilter(item)"
          >
            {{ item }}
          </Button>
        </div>
        <img src="../../../assets/icons/view.png" alt="" @click="view = !view" />
      </div>
    </div>
    <div class="values">
      <p>R$ <span v-if="view">2.476,20</span><span v-else>--,--</span></p>
      <div class="graphic">
        <div class="liberado" style="width: 30%" v-tooltip.top="'Liberado R$ 963,00'">30%</div>
        <div class="aguardando" style="width: 55%" v-tooltip.top="'Aguardando R$ 1080,70'">55%</div>
        <div class="bloqueado" style="width: 15%" v-tooltip.top="'Bloqueado R$ 432,50'">15%</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
const btnFilter = ["Realizados", "Agendados"];

export default {
  setup() {
    const view = ref(true);
    const filter = ref("Realizados");

    const isFilter = (item) => {
      if (item !== filter.value) {
        return "inative";
      }
    };

    const changeFilter = (item) => {
      filter.value = item;
    };

    return {
      view,
      filter,
      btnFilter,
      isFilter,
      changeFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.card-frequency {
  width: 100%;
  background: #fff;
  padding-top: 8px;
  padding: 24px;
  box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08),
    0px 3px 6px rgba(61, 71, 102, 0.06);
  border-radius: 8px;
  img:nth-child(1) {
    height: 86px;
    width: 86px;
    border-radius: 10px;
    padding: 5px;
  }
  h1 {
    color: #333333;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    line-height: 21px;
    margin: 0;
  }
}
.btn {
  width: max-content;
  height: 40px;
}
.btn-filters {
  display: flex;
  button {
    background: #ff6a33;
    border: 1px solid #ff6a33;
    &:hover {
      background: #ff6a33;
    }
    &:first-child {
      /* top-left | top-right | bottom-right | bottom-left */
      border-radius: 8px 0 0 8px;
    }
    &:last-child {
      /* top-left | top-right | bottom-right | bottom-left */
      border-radius: 0 8px 8px 0;
    }
  }
  .inative {
    background: #f1f5f8;
    border: 1px solid #f1f5f8;
    color: #828282;
    &:hover {
      background: #e2e6e9;
      border: 1px solid #e2e6e9;
      color: #828282;
    }
  }
}
.values {
  display: flex;
  justify-content: space-between;
  color: #828282;
  white-space: nowrap;
  margin-top: 19px;
  .graphic {
    display: flex;
    align-items: center;
    width: 320px;
    height: 24px;
    font-size: 12px;
    .liberado {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      color: #0A674F;
      font-weight: bold;
      background: #77e3c7;
      border-radius: 4px 0 0 4px;
      border: 1px solid #1dd1a1;
    }
    .aguardando {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      color: #995f28;
      font-weight: bold;
      background: #fedf9a;
      border: 1px solid #feca57;
    }
    .bloqueado {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      color: #8F3132;
      font-weight: bold;
      background: #ffa6a6;
      border-radius: 0px 4px 4px 0px;
      border: 1px solid #ff6b6b;
    }
  }
}
</style>