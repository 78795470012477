import imagem1 from "../../../assets/images/no-avatar.png"
import imagem2 from "../../../assets/avatares/patients/rubens.png"
import imagem3 from "../../../assets/avatares/patients/cristina.jpg"
import imagem4 from "../../../assets/images/profile/4.png"
import imagem5 from "../../../assets/images/profile/5.png"
import imagem6 from "../../../assets/images/profile/6.png"

export const dataCard = [
    {
        nome: "Rubens Melo",
        imagem: imagem2,
        lugar: "Hospital Israelita Albert Einstein",
        tipo_consulta: "Presencial",
        data: "24 de Novembro",
        horario: "14:30",
    },
    {
        nome: "Bruno Facioni",
        imagem: imagem1,
        lugar: "Clínica Moura",
        tipo_consulta: "Presencial",
        data: "24 de Novembro",
        horario: "16:30",
    },
    // {
    //     nome: "Cristina Borges",
    //     imagem: imagem3,
    //     lugar: "Clínica Moura",
    //     tipo_consulta: "Presencial",
    //     data: "13 de Fevereiro",
    //     horario: "16:00",
    // },
    // {
    //     nome: "Maira Hamada",
    //     imagem: imagem4,
    //     lugar: "Clínica Moura",
    //     tipo_consulta: "Presencial",
    //     data: "13 de Fevereiro",
    //     horario: "16:30",
    // },
    // {
    //     nome: "Nilton Gomes",
    //     imagem: imagem5,
    //     lugar: "Clínica Moura",
    //     tipo_consulta: "Presencial",
    //     data: "13 de Fevereiro",
    //     horario: "17:00",
    // },
    // {
    //     nome: "Letícia Barroso",
    //     imagem: imagem6,
    //     lugar: "Clínica Moura",
    //     tipo_consulta: "Presencial",
    //     data: "13 de Fevereiro",
    //     horario: "17:30",
    // },
];