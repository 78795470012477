<template>
  <div class="card-frequency">
    <div class="flex justify-content-between align-items-center">
      <h1>Resultados de exames por imagem</h1>
      <span class="p-input-icon-left">
        <i class="pi pi-search" />
        <InputText type="text" v-model="search" />
      </span>
    </div>
    <div class="content">
      <div class="message" v-for:="item in results">
        <div class="left">
          <img src="../../../assets/icons/link.png" alt="" />
          <div class="msg">
            <h1 class="font-bold">{{ item.name }}</h1>
            <p class="font-bold">{{ item.type }}</p>
          </div>
        </div>
        <p class="right">{{ item.date }}</p>
      </div>
      <div class="center-caret">
        <img class="h-full" src="../../../assets/icons/caret-down.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue"
const results = [
  {
    name: "Bruno Facioni",
    type: "Raio-x",
    date: "Em 10/10/2023 às 14:00hs",
  },
  {
    name: "Rubens Melo",
    type: "Raio-x",
    date: "Em 15/11/2023 às 14:30hs",
  },
  {
    name: "Cristina Borges",
    type: "Mamografia",
    date: "Em 17/11/2023 às 15:00hs",
  },
  {
    name: "Luana Petter",
    type: "Densitometria óssea",
    date: "Em 20/11/2023 às 15:30hs",
  },
];

export default {
  setup() {
    const search = ref()
    return { search, results };
  },
};
</script>

<style lang="scss" scoped>
.card-frequency {
  width: 100%;
  background: #fff;
  padding-top: 8px;
  padding: 24px;
  padding-bottom: 12px;
  box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08),
    0px 3px 6px rgba(61, 71, 102, 0.06);
  border-radius: 8px;
  img:nth-child(1) {
    height: 86px;
    width: 86px;
    border-radius: 10px;
    padding: 5px;
  }
  h1 {
    color: #333333;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    line-height: 21px;
    margin: 0;
  }
  .content {
    margin-top: 8px;
    .message {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 18px 0px;
      border-top: 1px dashed #a2c0d4;
      .left {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        img {
          width: max-content;
          height: max-content;
        }
        h1 {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #ff6a33;
        }
        p {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #4f4f4f;
        }
      }
      .right {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #b0bec5;
      }
    }
    .center-caret {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: max-content;
      }
    }
  }
}
.btn {
  width: max-content;
  height: 40px;
}
.btn-filters {
  display: flex;
  button {
    background: #ff6a33;
    border: 1px solid #ff6a33;
    &:hover {
      background: #ff6a33;
    }
    &:first-child {
      /* top-left | top-right | bottom-right | bottom-left */
      border-radius: 8px 0 0 8px;
    }
    &:last-child {
      /* top-left | top-right | bottom-right | bottom-left */
      border-radius: 0 8px 8px 0;
    }
  }
  .inative {
    background: #f1f5f8;
    border: 1px solid #f1f5f8;
    color: #828282;
    &:hover {
      background: #e2e6e9;
      border: 1px solid #e2e6e9;
      color: #828282;
    }
  }
}
.p-input-icon-left {
  input {
    height: 30px;
    background: #f1f5f8;
    border-radius: 8px;
    border: 1px solid #f1f5f8;
  }
  .pi,
  .pi-search {
    font-size: 12px !important;
    color: #bdbdbd !important;
    cursor: pointer;
  }
}
</style>