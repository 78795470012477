<template>
  <div
    class="card-frequency flex justify-content-between align-items-start"
    :class="borderTop ? 'border-top' : ''"
    v-for:="item in dataCard"
  >
    <div class="flex">
      <img :src="item.imagem" alt="profile" />
      <div class="profile mt-2">
        <h1>{{ item.nome }}</h1>

        <div class="row w-max" style="gap: 0">
          <p style="font-size: 12px">{{ item.lugar }}</p>
        </div>
        <div class="row w-max">
          <p style="font-size: 12px">{{ item.tipo_consulta }}</p>
        </div>
      </div>
    </div>
    <div class="flex flex-column justify-content-end p-1">
      <div class="profile">
        <div class="row flex justify-content-end">
          <div class="agendado" style="font-size: 13px">Agendado</div>
        </div>
        <div class="row align-self-end">
          <p class="font-bold" style="color: #2d313d">{{ item.data }}</p>
        </div>
        <div class="row-card clock align-self-end">
          <img src="../../../assets/icons/clock.png" alt="" />
          <p class="ml-1">{{ item.horario }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["dataCard", "borderTop"],
  setup() {

    return {};
  },
};
</script>

<style lang="scss" scoped>
.card-frequency {
  width: 100%;
  background: #fff;
  margin: 8px 0;
  padding-top: 8px;
  img:nth-child(1) {
    height: 86px;
    width: 86px;
    border-radius: 10px;
    padding: 5px;
  }
}
.border-top {
  border-top: 1px dashed #e0e0e0;
}
.profile {
  margin-top: 12px !important;
  display: flex;
  flex-direction: column;
  margin: 5px;
  margin-left: 0;
  p {
    color: #828282;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
  }
  h1 {
    margin: 0;
    color: #2d313d;
    font-size: 15px;
    line-height: 17px;
    font-weight: 600;
  }
}
.circle-blue {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  background: red;
  border-radius: 100%;
  .branco {
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 100%;
  }
}
.tipo {
  width: 4px;
  height: 12px;
  background: #a2c0d4;
  border-radius: 15px;
}
.agendado {
  font-weight: bold;
  border: 1px solid #27ae60;
  color: #27ae60;
  border-radius: 20px;
  width: max-content;
  padding: 2px 12px;
}
.row-card {
  display: flex;
  align-items: center;
}
.clock {
  font-weight: bold;
  img {
    width: 16px !important;
    height: 16px !important;
    padding: 0 !important;
  }
  p {
    color: #2d313d;
    font-weight: bold !important;
  }
}
</style>