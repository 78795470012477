<template>
  <div class="card-frequency">
    <div
      class="flex justify-content-between align-items-center"
      style="margin-bottom: 8px"
    >
      <h1>Conversas recentes com pacientes</h1>
      <Button class="btn-avatar">
        <img src="../../../assets/icons/avatar.png" alt="" />
      </Button>
    </div>
    <div class="row-gray">
      <span class="p-input-icon-left">
        <i class="pi pi-search" />
        <InputText type="text" v-model="search" />
      </span>
      <div class="container-filters" v-for:="itemFilter in btnFilter">
        <p :class="isFilter(itemFilter)" @click="changeFilter(itemFilter)">
          {{ itemFilter }}
        </p>
      </div>
    </div>
    <div class="row-gray" v-for:="item in filterMsg">
      <div class="flex">
        <img class="img-profile" :src="item.img" alt="" />
        <div class="msg">
          <h1>{{ item.nome }}</h1>
          <p :class="item.lido ? 'lida' : 'naolida'">{{ item.msg }}</p>
        </div>
      </div>
      <div
        class="
          flex flex-column
          justify-content-center
          align-items-center
          naolida
        "
        :class="item.lido ? 'lida' : 'naolida'"
      >
        <p>14:30</p>
        <img
          style="margin-top: 6px"
          src="../../../assets/icons/icon_msg.png"
          alt=""
        />
      </div>
    </div>
    <div class="flex justify-content-center" style="margin-top: 12px">
      <img src="../../../assets/icons/caret-down.png" alt="" />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
const btnFilter = ["Todos", "Lidos", "Novos"];
import imagem1 from "../../../assets/images/profile/1.png";
import imagem2 from "../../../assets/images/profile/2.png";
import imagem3 from "../../../assets/images/profile/3.png";
import imagem4 from "../../../assets/images/profile/4.png";
import imagem5 from "../../../assets/images/profile/5.png";

const msg = [
  {
    nome: "Carlos Souza",
    img: imagem1,
    msg: "Oi! Tudo bem?",
    hora: "14:30",
    lido: false,
  },
  {
    nome: "Maitê Canto",
    img: imagem2,
    msg: "Obrigado, em breve eu retorno",
    hora: "14:30",
    lido: true,
  },
  {
    nome: "Abelardo Oliveira",
    img: imagem3,
    msg: "Estou perto, desculpe o atraso!",
    hora: "14:30",
    lido: true,
  },
  {
    nome: "Maira Hamada",
    img: imagem4,
    msg: "Oi, tudo bem? Eu não estou te v...",
    hora: "14:30",
    lido: true,
  },
  {
    nome: "Nilton Gomes",
    img: imagem5,
    msg: "Shooow, segunda-feira fica melhor...",
    hora: "14:30",
    lido: true,
  },
];

export default {
  setup() {
    const filter = ref("Todos");
    const search = ref("");
    const filterMsg = ref(msg);

    const isFilter = (item) => {
      if (item === filter.value) {
        return "true";
      }
    };

    const changeFilter = (item) => {
      filter.value = item;
      switch (item) {
        case "Todos":
          filterMsg.value = msg;
          break;
        case "Lidos":
          const msgLidos = msg.filter((item) => item.lido);
          filterMsg.value = msgLidos;
          break;
        case "Novos":
          const msgNovos = msg.filter((item) => !item.lido);
          filterMsg.value = msgNovos;
          break;
        default:
          filterMsg.value = msg;
      }
    };

    return {
      msg,
      search,
      filterMsg,
      filter,
      btnFilter,
      isFilter,
      changeFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.card-frequency {
  width: 100%;
  background: #fff;
  padding-top: 8px;
  padding: 24px;
  padding-bottom: 12px;
  box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08),
    0px 3px 6px rgba(61, 71, 102, 0.06);
  border-radius: 8px;
  h1 {
    color: #333333;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    line-height: 21px;
    margin: 0;
  }
}
.btn {
  width: max-content;
  height: 40px;
}
.btn-avatar {
  display: flex;
  height: 40px;
  background: white;
  border: 1px solid white;
  width: max-content;
  &:hover {
    background: white !important;
    border: 1px solid white;
  }
}
.row-gray {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f2f2f2;
  height: 48px;
  border-radius: 8px;
  padding: 8px;
  margin-top: 8px;
  .img-profile {
    width: 32px;
    height: 32px;
    border-radius: 100%;
  }
  h1 {
    color: black;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 2px;
  }
  .pi,
  .pi-search {
    font-size: 12px !important;
    color: #bdbdbd !important;
    cursor: pointer;
  }
  input {
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(255, 255, 255, 0.8);
  }
  .naolida {
    color: #ff4500;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #828282;
    }
    img {
      filter: invert(43%) sepia(81%) saturate(4882%) hue-rotate(359deg)
        brightness(99%) contrast(110%);
    }
  }
  .lida {
    color: #bdbdbd;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #bdbdbd;
    }
    img {
      filter: none !important;
    }
  }
  .msg {
    margin-left: 8px;
  }
  .container-filters {
    display: flex;
    gap: 8px;
    cursor: pointer;
    p {
      color: #ff6a33;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      padding: 0px 12px;
    }
    .true {
      background: white !important;
      border-radius: 8px;
      box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
    }
  }
}
</style>