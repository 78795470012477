<template>
  <div class="card-compromisso">
    <div class="next-patient">
      <h1>Próximo paciente</h1>
      <div class="flex justify-content-between">
        <div class="profile">
          <img class="avatar" src="../../../assets/avatares/patients/luana.jpeg" alt="" />
          <div class="data">
            <h1>Luana Petter</h1>
            <div class="flex align-items-center">
              <p>Hospital Israelita Albert Einstein</p>
            </div>
            <div class="flex align-items-center">
              <p>Presencial</p>
            </div>

            <p>Consulta</p>
          </div>
        </div>
        <div class="flex flex-column align-items-end">
          <img src="../../../assets/icons/arrows.png" alt="" />
          <Button class="mt-12" label="Acessar" />
        </div>
      </div>
      <Divider type="dashed" />
      <div class="flex align-items-center justify-content-between">
        <div class="flex align-items-center">
          <img src="../../../assets/icons/clock_dashboard.png" alt="" />
          <p class="font-bold" style="color: white; margin-left: 4px">11:39</p>
          <p class="font-bold" style="color: #333333; margin-left: 4px">
            (00:60)
          </p>
        </div>
        <p class="ellipse">...</p>
      </div>
    </div>
    <div class="p-14" style="padding-bottom: 12px !important;">
      <div class="flex justify-content-between align-items-center">
        <h1>Outros Compromissos</h1>
        <div class="date-calendar">
          <img
            name="calendar"
            src="../../../assets/icons/myservices_calendar.png"
            alt=""
          />
          <p>Novembro - 2023</p>
        </div>
      </div>
      <!-- <img
        src="../../../assets/images/calendar_dashboard.png"
        style="margin-top: 12px; width: 100%"
        alt=""
      />
      <img
        src="../../../assets/images/legenda_calendar.png"
        style="margin-top: 8px"
        alt=""
      /> -->
      <Card :dataCard="dataCard" :borderTop="true" />
      <div class="flex justify-content-center">
        <img src="../../../assets/icons/caret-down.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { dataCard } from "./dataCard.js";
import Card from "./Card.vue";

export default {
  components: {
    Card,
  },
  setup() {
    return { dataCard };
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  width: 100%;
  gap: 16px;
  margin-top: 28px;
  .content-left {
    width: 469px;
    min-height: 1614px;
  }
  .content-right {
    width: 609px;
  }
  h1 {
    color: #4f4f4f;
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
  }
  .p-14 {
    padding: 14px;
  }
}
.card-compromisso {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  // min-height: 1022px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08),
    0px 3px 6px rgba(61, 71, 102, 0.06);
  .next-patient {
    padding: 8px 22px;
    background: #ff6a33;
    min-height: 175px;
    border-radius: 10px;
    h1 {
      color: #333333;
      font-size: 18px;
      font-style: normal;
      font-weight: bold;
      line-height: 21px;
      margin: 0;
    }
    button {
      background: white;
      color: #ff6a33;
      height: 40px;
      padding: 0 31px;
    }
    .mt-12 {
      margin-top: 12px;
    }
    .profile {
      display: flex;
      margin-top: 8px;
      .avatar{
        width: 75px;
        height: 75px;
        border-radius: 8px;
      }
      .data {
        display: flex;
        flex-direction: column;
        margin-left: 8px;
        h1 {
          color: #333333;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 21px;
          margin: 0;
        }
        p {
          color: white;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 21px;
          margin: 0;
        }
        .circle {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 12px;
          height: 12px;
          background: #56ccf2;
          border-radius: 100%;
          margin-right: 4px;
          .white {
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background-color: white;
          }
        }
        .type {
          width: 4px;
          height: 12px;
          background-color: white;
          border-radius: 8px;
          margin-right: 4px;
        }
      }
    }
  }
  .date-calendar {
    display: flex;
    align-items: center;
    gap: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #ff6a33;
  }
  .ellipse {
    color: #f1f5f8 !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    opacity: 0.2;
  }
}
</style>