<template>
  <div class="card-frequency">
    <div class="flex justify-content-between align-items-center">
      <h1>Segunda Opinião</h1>
      <span class="p-input-icon-left">
        <i class="pi pi-search" />
        <InputText type="text" v-model="search" />
      </span>
    </div>
    <div class="content">
      <div class="message">
        <h1>Você recebeu uma solicitação de segunda opinião.</h1>
        <p>20 NOV 2023 / 14:15</p>
      </div>
      <div class="profiles">
        <div class="doctor">
          <h1>Doutor</h1>
          <div class="profile">
            <img src="../../../assets/images/doctor.png" alt="" />
            <div class="flex flex-column" style="margin-left: 8px">
              <h1>Dr. Petrônio Atayde</h1>
              <p>Cardiologista</p>
            </div>
          </div>
        </div>
        <div class="patient">
          <h1>Paciente</h1>
          <div class="profile">
            <img src="../../../assets/images/patient.png" alt="" />
            <div class="flex flex-column" style="margin-left: 8px">
              <h1>Harold Bridges</h1>
              <p>João Pessoa - PB</p>
            </div>
          </div>
        </div>
      </div>
      <div class="box-text">
        Caro Dr. Sodré, estou precisando da sua opinião para a análise do
        material coletado do paciente. Veja a imagem em anexo e o prontuário do
        paciente.
      </div>
      <div class="btns">
        <Button class="secondary" label="Exame" />
        <Button label="Prontuário" />
      </div>
      <div class="center-caret">
        <img class="h-full" src="../../../assets/icons/caret-down.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
const btnFilter = ["Realizados", "Agendados"];

export default {
  setup() {
    const search = ref()
    const filter = ref("Realizados");

    const isFilter = (item) => {
      if (item !== filter.value) {
        return "inative";
      }
    };

    const changeFilter = (item) => {
      filter.value = item;
    };

    return {
      search,
      filter,
      btnFilter,
      isFilter,
      changeFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.card-frequency {
  width: 100%;
  background: #fff;
  padding-top: 8px;
  padding: 24px;
  box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08),
    0px 3px 6px rgba(61, 71, 102, 0.06);
  border-radius: 8px;
  padding-bottom: 12px;
  img:nth-child(1) {
    height: 86px;
    width: 86px;
    border-radius: 10px;
    padding: 5px;
  }
  h1 {
    color: #333333;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    line-height: 21px;
    margin: 0;
  }
  .content {
    margin-top: 6px;
    .message {
      padding: 8px 0px;
      margin-top: 8px;
      border-top: 1px dashed #a2c0d4;
      border-bottom: 1px dashed #a2c0d4;
      h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #4f4f4f;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #4f4f4f;
      }
    }
    .profiles {
      display: flex;
      margin: 8px 0;
      .doctor {
        width: calc(100% / 2);
        padding: 0 20px;
        border-right: 1px dashed #a2c0d4;
        h1 {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          color: #333333;
        }
      }
      .patient {
        width: calc(100% / 2);
        padding: 0 20px;
        h1 {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          color: #333333;
        }
      }
      .profile {
        display: flex;
        align-items: center;
        h1 {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #ff6a33;
        }
        img {
          width: 54px;
          height: 56px;
          padding: 0 !important;
          margin: 0 !important;
        }
      }
    }
    .box-text {
      background: #f1f5f8;
      min-height: 88px;
      box-sizing: border-box;
      border: 1px dashed #a2c0d4;
      border-radius: 8px;
      padding: 8px 18px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
    .btns {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
      margin-top: 8px;
      button {
        width: max-content;
        height: 40px;
      }
      .secondary{
        background: white;
        color: #ff5414;
      }
    }
    .center-caret {
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: max-content;
      }
    }
  }
}
.btn {
  width: max-content;
  height: 40px;
}
.btn-filters {
  display: flex;
  button {
    background: #ff6a33;
    border: 1px solid #ff6a33;
    &:hover {
      background: #ff6a33;
    }
    &:first-child {
      /* top-left | top-right | bottom-right | bottom-left */
      border-radius: 8px 0 0 8px;
    }
    &:last-child {
      /* top-left | top-right | bottom-right | bottom-left */
      border-radius: 0 8px 8px 0;
    }
  }
  .inative {
    background: #f1f5f8;
    border: 1px solid #f1f5f8;
    color: #828282;
    &:hover {
      background: #e2e6e9;
      border: 1px solid #e2e6e9;
      color: #828282;
    }
  }
}
.p-input-icon-left {
  input {
    height: 30px;
    background: #f1f5f8;
    border-radius: 8px;
    border: 1px solid #f1f5f8;
  }
  .pi,
  .pi-search {
    font-size: 12px !important;
    color: #bdbdbd !important;
    cursor: pointer;
  }
}
</style>