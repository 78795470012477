<template>
  <div class="card-frequency">
    <div class="flex justify-content-between align-items-center p-24">
      <h1>Alertas e notificações</h1>
      <span class="p-input-icon-left">
        <i class="pi pi-search" />
        <InputText type="text" v-model="search" />
      </span>
    </div>
    <div class="content">
      <div class="notification">
        <div class="message p-24 pt-0 pb-18">
          <h1>23 NOV 2023 / 16:32</h1>
          <h2>PLATAFORMA</h2>
          <p>
            O Exame do paciente Rubens Melo já encontra-se na plataforma.
          </p>
          <Button label="Ver mais" />
        </div>
      </div>
      <div class="alert">
        <div class="message pb-18">
          <h1>24 NOV 2023 / 07:49</h1>
          <h2>PLATAFORMA</h2>
          <p>
            O boleto da mensalidade de Janeiro, com vencimento em 20/02, já está
            disponível para visualização.
          </p>
          <Button label="Ver boleto" />
        </div>
        <img src="../../../assets/icons/caret-down.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
const btnFilter = ["Realizados", "Agendados"];

export default {
  setup() {
    const search = ref()
    const filter = ref("Realizados");

    const isFilter = (item) => {
      if (item !== filter.value) {
        return "inative";
      }
    };

    const changeFilter = (item) => {
      filter.value = item;
    };

    return {
      search,
      filter,
      btnFilter,
      isFilter,
      changeFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.card-frequency {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background: #fff;
  min-height: 393px;
  box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08),
    0px 3px 6px rgba(61, 71, 102, 0.06);
  border-radius: 8px;
  img:nth-child(1) {
    height: 86px;
    width: 86px;
    border-radius: 10px;
    padding: 5px;
  }
  h1 {
    color: #333333;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    line-height: 21px;
    margin: 0;
  }
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-height: 315px;
    .notification {
      display: flex;
      flex-direction: column;
      button{
        background: #F1F5F8 !important;
        border: 1px solid #F1F5F8;
        color: #FF6A33;
      }
    }
    .alert {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 210px;
      padding: 28px 32px;
      padding-bottom: 12px;
      background: #f1f5f8;
      border-radius: 0px 0px 8px 8px;
      border-top: 1px dashed #a2c0d4;
    }
    .message {
      h1 {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #4f4f4f;
      }
      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: #ff6a33;
        margin: 8px 0;
      }
      p{
        min-height: 45px;
      }
      button {
        width: max-content;
        height: max-content;
        background: #ff6a33;
        border-radius: 8px;
        padding: 0;
        width: 105px;
        height: 20px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        margin-top: 8px;
        box-shadow: none;
      }
    }
  }
  .p-24 {
    padding: 24px;
  }
  .pb-18{
    padding-bottom: 18px;
  }
}
.btn {
  width: max-content;
  height: 40px;
}
.btn-filters {
  display: flex;
  button {
    background: #ff6a33;
    border: 1px solid #ff6a33;
    &:hover {
      background: #ff6a33;
    }
    &:first-child {
      /* top-left | top-right | bottom-right | bottom-left */
      border-radius: 8px 0 0 8px;
    }
    &:last-child {
      /* top-left | top-right | bottom-right | bottom-left */
      border-radius: 0 8px 8px 0;
    }
  }
  .inative {
    background: #f1f5f8;
    border: 1px solid #f1f5f8;
    color: #828282;
    &:hover {
      background: #e2e6e9;
      border: 1px solid #e2e6e9;
      color: #828282;
    }
  }
}
.p-input-icon-left {
  input {
    height: 30px;
    background: #f1f5f8;
    border-radius: 8px;
    border: 1px solid #f1f5f8;
  }
  .pi,
  .pi-search {
    font-size: 12px !important;
    color: #bdbdbd !important;
    cursor: pointer;
  }
}
</style>